var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"show":_vm.showModal,"modal-classes":"modal-secondary add-file-dialog","size":"lg"}},[_c('div',{staticClass:"elite-tabs-wrapper"},[_c('tabs',{staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper","tabNavClasses":"nav elite-tabs","value":_vm.currentTab},on:{"onTabChanged":_vm.tabChanged}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":true}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("FILES.FILE"))+" ")]),_c('dropzone-file-upload',{attrs:{"url":_vm.uploadUrl,"multiple":""},on:{"addedfile":() => {
              _vm.loading = true;
            },"queuecomplete":_vm.filesUpdated,"removedfile":_vm.filesUpdated,"error":_vm.onUploadError}})],1),(_vm.$currentUserCan(_vm.$permissions.PERM_MANAGE_ACCESS_FILES))?_c('tab-pane',{attrs:{"title":"global","id":"2"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("FILES.SHARE"))+" ")]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATIONS')}`,"placeholder":_vm.$t('COMMON.LOCATIONS')}},[_c('locations-selector',{attrs:{"locations":_vm.fileRestrictions.allowedLocations,"filterable":true,"showAll":false,"disabled":!_vm.fileRestrictions.organization.id},on:{"locationsChanged":(locations) => {
                _vm.fileRestrictions.allowedLocations = locations;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ROLES))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ROLES')}`,"placeholder":_vm.$t('COMMON.ROLES')}},[_c('roles-selector',{attrs:{"roles":_vm.fileRestrictions.roles,"filterable":true,"filterOrganization":_vm.fileRestrictions.organization.id,"disabled":!_vm.fileRestrictions.organization.id},on:{"rolesChanged":(roles) => {
                _vm.fileRestrictions.roles = roles;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.roles}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.USERS')}`,"placeholder":_vm.$t('COMMON.USERS')}},[_c('users-selector',{attrs:{"users":_vm.fileRestrictions.users,"filterable":true,"filterOrganization":_vm.fileRestrictions.organization.id,"disabled":!_vm.fileRestrictions.organization.id},on:{"usersChanged":(users) => {
                _vm.fileRestrictions.users = users;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.users}})],1):_vm._e()],1)],1),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn add",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.addFiles}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("FILES.ADD_FILE"))+" ")]),_c('button',{staticClass:"btn btn-link ml-auto close",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }